import React, { useRef, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ArrowLeftSVG from './../assets/svg/arrow-left.svg'
import ArrowRightSVG from './../assets/svg/arrow-right.svg'
import DotSVG from './../assets/svg/dot.svg'

import Glide, { Controls } from '@glidejs/glide/dist/glide.modular.esm'
import '@glidejs/glide/dist/css/glide.core.min.css'

const CareersTestimonials = () => {
  const { allDatoCmsTestimonial } = useStaticQuery(graphql`
    query CareersTestimonialQuery {
      allDatoCmsTestimonial(
        filter: { categories: { elemMatch: { title: { eq: "Careers" } } } }
      ) {
        edges {
          node {
            id
            name
            link: jobTitle
            body
          }
        }
      }
    }
  `)

  // Set reference for slider element
  const sliderRef = useRef(null)

  useEffect(() => {
    // Attach slider on mount
    const testimonialSlider = sliderRef.current
    const testimonialGlider = new Glide(testimonialSlider, {
      focusAt: 'center',
      perView: 1,
    }).mount({ Controls })

    // Remove slider on cleanup
    return () => testimonialGlider.destroy()
  }, []) // Empty array ensures that effect is only run on mount

  const testimonials = allDatoCmsTestimonial.edges

  return (
    <section id="testimonials" className="c-testimonial">
      <div className="c-testimonial__outer container">
        <div className="c-testimonial__inner">
          <div ref={sliderRef} className="slider">
            <div className="glide__track" data-glide-el="track">
              <ul className="glide__slides">
                {testimonials.map(({ node }) => (
                  <li key={node.id} className="glide__slide">
                    <div className="c-testimonialItem glide__slide row">
                      <div className="col-12">
                        <q className="c-testimonial__body d-block">
                          <div className="text-center">{node.body}</div>
                        </q>
                        <div className="text-right">
                          <a
                            href={node.link}
                            rel="noopener noreferrer"
                            target="__blank"
                            className="c-testimonialLink"
                          >
                            View review on Glassdoor
                          </a>
                          <ArrowRightSVG className="c-icon ml-1" />
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="glide__arrows d-flex" data-glide-el="controls">
              <button
                className="glide__arrow glide__arrow--left mr-3"
                data-glide-dir="<"
              >
                <ArrowLeftSVG className="c-icon" />
              </button>
              <div className="glide__bullets" data-glide-el="controls[nav]">
                {testimonials.map(({ node }, index) => (
                  <span
                    key={node.id}
                    className="glide__bullet"
                    data-glide-dir={`=${index}`}
                  >
                    <DotSVG />
                  </span>
                ))}
              </div>
              <button
                className="glide__arrow glide__arrow--right ml-3"
                data-glide-dir=">"
              >
                <ArrowRightSVG className="c-icon" />
              </button>
            </div>

            <div className="row mt-5">
              <div className="col-12 text-center">
                <p>
                  <a
                    href="https://www.glassdoor.co.uk/Overview/Working-at-Erudus-EI_IE2875644.11,17.htm"
                    rel="noopener noreferrer"
                    target="__blank"
                    className="c-testimonialLink"
                  >
                    Click here
                  </a>{' '}
                  to see our Glassdoor profile and find out what it’s like to
                  work at Erudus.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CareersTestimonials
