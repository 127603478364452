import React, { Fragment } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import dayjs from 'dayjs'

import Page from '../components/Page'
import SEO from '../components/SEO'
import Schema from '../components/Schema'
import CareersTestimonials from '../components/CareersTestimonials'
import Newsletter from '../components/Newsletter'

import { isEven } from '../utils/num'

import LocationPinSVG from '../assets/svg/location-pin.svg'
import CalendarSVG from '../assets/svg/calendar.svg'

const CareersPage = () => {
  const { markdownRemark, allDatoCmsJob } = useStaticQuery(graphql`
    query CareersPageQuery {
      markdownRemark(frontmatter: { path: { eq: "/careers" } }) {
        frontmatter {
          title
          description
          benefits {
            blurbs {
              title
              text
              image {
                src {
                  childImageSharp {
                    fluid(maxWidth: 450) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                alt
              }
            }
            perks
          }
          values {
            blurbs {
              title
              text
              icon
            }
          }
          opportunities {
            none
          }
        }
      }
      allDatoCmsJob {
        edges {
          node {
            jobTitle
            slug
            closingDate
            salary
            location
            contractType
          }
        }
      }
    }
  `)

  const pageData = markdownRemark.frontmatter
  const allJobs = allDatoCmsJob.edges.filter(({ node }) => {
    if (!node.closingDate) return true

    const closingDate = dayjs(new Date(node.closingDate))

    return !dayjs(new Date()).isAfter(closingDate, 'day')
  })

  return (
    <Page className="p-careers has-fingerPrints">
      <SEO title={pageData.title} description={pageData.description} />
      <Schema
        breadcrumbs={[
          {
            name: 'Careers',
            uri: '/careers',
          },
        ]}
      />

      <section className="c-section c-section--pageHeader">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-pageHeader">
              <div className="row">
                <div className="col-12 col-md-6 pt-lg-3 pt-xl-5">
                  <div>
                    <span className="c-pageHeader__title pt-lg-3 pt-xl-5">
                      Careers
                    </span>
                  </div>
                  <h1 className="c-pageHeader__intro">
                    <strong>Join the team</strong> feeding
                    <br />
                    data to the food industry
                  </h1>
                </div>
                <div className="col-12 col-md-6 text-center">
                  <img
                    className="img-fluid"
                    src="/images/icons/careers/Lead-Spot.png"
                    srcSet="/images/icons/careers/Lead-Spot.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="c-wave c-wave--bottom">
          <svg viewBox="0 0 500 150" preserveAspectRatio="none">
            <path
              style={{ stroke: 'none', fill: '#F4F4F4' }}
              d="M-5.92,115.95 C125.56,-215.63 292.04,320.22 500.84,21.20 L500.00,150.00 L0.00,150.00 Z"
            ></path>
          </svg>
        </div>
      </section>

      <section id="our-story" className="c-section c-section--whyErudus">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="row text-left">
              <div className="col-12 col-md-6">
                <h3 className="c-careersTitle">Why Erudus?</h3>
                <p className="f-introPara">
                  If you’ve got this far, you probably know that we’re much more
                  than a data company. Thanks to our passionate,
                  forward-thinking team, Erudus is becoming the go-to source of
                  knowledge for the food industry.
                </p>
              </div>
              <div className="col-12 col-md-6 text-center">
                <img
                  srcSet="/images/living-wage.png 1x, /images/living-wage.png 2x"
                  src="/images/living-wage.png"
                  className="c-livingWageImage img-fluid"
                  alt="We are a living wage employer"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-section c-section--forBenefits c-section--forBenefits--careers">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-forBenefits">
              {pageData.benefits.blurbs.map((blurb, i) => (
                <div
                  key={blurb.title}
                  className={`c-forBenefitsCard ${
                    !isEven(i) ? 'c-forBenefitsCard--reversed' : ''
                  }`}
                >
                  <div className="c-forBenefitsCard__block c-forBenefitsCard__block--text">
                    <h5 className="c-forBenefitsCard__header">{blurb.title}</h5>
                    <div className="c-forBenefitsCard__text">
                      <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                    </div>
                  </div>

                  <div className="c-forBenefitsCard__block c-forBenefitsCard__block--image">
                    <Img
                      fluid={blurb.image.src.childImageSharp.fluid}
                      title={blurb.title}
                      alt={blurb.image.alt}
                      className="c-forBenefitsCard__image img-fluid"
                      style={{ maxWidth: '410px' }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <CareersTestimonials />

      <section id="our-story" className="c-section c-section--ourValues">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="row text-left">
              <div className="col-12 col-lg-4 mb-5">
                <h3 className="c-careersTitle">Our values</h3>
                <p className="f-introPara">
                  We’re a growing team with unshakable core values.
                </p>
                <p>
                  They guide the way we work with our customers and each other.
                </p>
              </div>
              <div className="col-12 col-lg-8">
                {pageData.values.blurbs.map((blurb) => (
                  <div key={blurb.title} className="c-ourValuesBlurb row">
                    <div className="col-12 col-md-4 col-lg-3 text-md-center mb-3">
                      <img
                        className="c-ourValuesBlurb__img img-fluid"
                        src={`/images/icons/careers/${blurb.icon}.png`}
                        alt={`${blurb.title} icon`}
                        srcSet={`/images/icons/careers/${blurb.icon}.svg`}
                      />
                    </div>
                    <div className="col-12 col-md-8 col-lg-9">
                      <div className="c-ourValuesBlurb__text">
                        <h1 className="pb-4">
                          <strong>{blurb.title}</strong>
                        </h1>
                        <p className="pb-0">{blurb.text}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="our-story" className="c-section c-section--opportunities">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="row text-left">
              <div className="mb-3 mb-lg-0 col-12 col-lg-5">
                <h3 className="c-careersTitle">Who we’re looking for</h3>
                <p className="f-introPara">
                  Are you ready to make a difference?
                </p>
                <p>
                  Are you a team player with a good sense of humour? Are you
                  forward-thinking and self-motivated? Are you keen to test your
                  talent?
                </p>
                <p>
                  There are lots of exciting roles within the Erudus team, and
                  plenty of opportunity to grow with the company. Everyone is
                  valued for their unique contribution, and well rewarded for
                  their efforts to unite the food industry.
                </p>
              </div>
              <div className="mt-5 mt-lg-0 col-12 col-lg-6 offset-lg-1">
                <h3 className="c-careersTitle">Current opportunities</h3>
                {allJobs.length > 0 ? (
                  <JobListing jobs={allJobs} perks={pageData.benefits.perks} />
                ) : (
                  <NoJobs html={pageData.opportunities.none} />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

const JobListing = ({ jobs, perks }) => {
  return (
    <Fragment>
      {jobs.map(({ node }) => (
        <div key={node.jobTitle} className="c-careersPost">
          <h2 className="c-careersPost__title mb-4">
            <Link to={`/careers/${node.slug}`} className="c-careersPost__link">
              {node.jobTitle}
            </Link>
          </h2>
          <p>
            <LocationPinSVG
              className="mr-2"
              style={{
                marginBottom: '.15rem',
              }}
            />
            {node.location}
          </p>
          <p className="mb-4">
            <CalendarSVG
              className="mr-2"
              style={{
                marginBottom: '.15rem',
              }}
            />
            {node.contractType}
          </p>
          <p>{node.salary}</p>
          {perks.map((perk, i) => (
            <p key={`${node.slug}_perk_${i}`}>{perk}</p>
          ))}
        </div>
      ))}
    </Fragment>
  )
}

const NoJobs = ({ html }) => (
  <p
    dangerouslySetInnerHTML={{
      __html: html,
    }}
  />
)

export default CareersPage
